<template>
    <div class="contents">
        <h1>更新履歴</h1>

        <table>
            <tr>
                <th>更新日</th>
                <th>内容</th>
            </tr>
            <tr v-for="(history, index) in histories" :key="index">
                <td>{{ history.date }}</td>
                <td class="history-contents">
                    <span v-html="history.contents"></span>
                </td>
            </tr>
        </table>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject, computed } from "vue";
import { key as historyStoreKey } from "@/stores/history";

export default defineComponent({
    name: "History",
    setup() {
        const historyStore = inject(historyStoreKey);
        const histories = computed(() => {
            let his = historyStore?.histories.concat();
            his = his?.reverse();
            return his;
        });
        return {
            histories,
        };
    },
});
</script>

<style scoped>
.contents {
    text-align: center;
}
tr {
    height: 2rem;
}
td {
    padding: 10px;
    vertical-align: top;
}
.history-contents {
    text-align: left;
}
.history-contents ul {
    margin-top: 1px;
    margin-bottom: 1px;
    list-style-type: disc;
}
</style>