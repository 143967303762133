import { InjectionKey, reactive } from "vue";
import { ItemType } from "./items";

export type PopupParam = {
    title: string;
    width?: number;
    height?: number;
    url?: string;
    component?: string;
};
// eslint-disable-next-line
export default function operationStore() {
    const popup = reactive({
        visible: false,
        title: 'タイトル',
        width: 640,
        height: 450,
        url: null as string | null,
        component: null as string | null,
    });
    const showPopup = (param: PopupParam) => {
        popup.title = param.title;
        popup.url = param.url !== undefined ? param.url : null;
        popup.component = param.component !== undefined ? param.component : null;
        popup.width = param.width !== undefined ? param.width : 640;
        popup.height = param.height !== undefined ? param.height : 450;
        popup.visible = true;
    };

    return {
        itemClicked(item: ItemType) {
            if (item.click === undefined) return;
            if (item.click.popup !== undefined) {
                showPopup(item.click.popup);
            } else if (item.click.url !== undefined) {
                //blank表示
                window.open(item.click.url);
            }
        },
        popup,
        showPopup,
        hidePopup() {
            popup.visible = false;
        }
    }
}
export const key: InjectionKey<ReturnType<typeof operationStore>> = Symbol('operationStore');
