<!-- 説明ボックス -->
<template>
    <div id="explain_box">
        <div id="exp_title_box">{{ name }}</div>
        <div v-if="isVisible" id="exp_main_box">
            <div id="exp_image_box">
                <span></span><img id="exp_image" :src="img" />
            </div>
            <div id="exp_msg_box">
                <p>{{ message }}</p>
                <p class="emphasis">{{ specialMessage }}</p>
            </div>
        </div>
        <div v-if="!isVisible" id="exp_default_msg_box">
            <p>部屋の中をご自由に探索してください。</p>
            <p>物によっては、クリックすると詳しく見れます。</p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject, computed } from "vue";
import { key as itemStoreKey } from "@/stores/items";

export default defineComponent({
    name: "ExplainBox",
    setup() {
        const itemStore = inject(itemStoreKey);

        //説明対象のアイテムがあるか
        const isVisible = computed(() => {
            const item = itemStore?.hoverItem;
            return item !== undefined;
        });

        const name = computed(() => {
            const item = itemStore?.hoverItem;
            return item === undefined ? "" : item.name;
        });

        const message = computed(() => {
            const item = itemStore?.hoverItem;
            return item === undefined ? "" : item.explain.message;
        });

        const specialMessage = computed(() => {
            const item = itemStore?.hoverItem;
            return item === undefined ? "" : item.explain.specialMessage;
        });

        const img = computed(() => {
            const item = itemStore?.hoverItem;
            return item === undefined
                ? ""
                : item.explain.img !== undefined
                ? item.explain.img
                : item.img;
        });

        return {
            isVisible,
            name,
            message,
            specialMessage,
            img,
        };
    },
});
</script>

<style scoped>
#exp_title_box {
    width: 100%;

    text-align: center;
    font-weight: bold;
}

#exp_default_msg_box {
    margin-top: 5px;
    padding: 5px;
}

#exp_main_box {
    width: 100%;
    height: 200px;
    margin-top: 5px;
}

#exp_image_box {
    width: 100px;
    height: 100px;
    float: left;

    display: block;
    text-align: center;

    background-color: #ffffff;
}

#exp_image_box:before {
    content: "";
    height: 100%;
    vertical-align: middle;
    width: 0px;
    display: inline-block;
}
#exp_image_box:after {
    content: "";
    height: 100%;
    width: 0px;
    display: block;
}

#exp_image {
    vertical-align: middle;
}
#exp_msg_box {
    margin-left: 115px;
    padding-right: 5px;
    white-space: pre-wrap;
}

#exp_msg_box p {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.emphasis {
    color: #ff3333;
}
</style>
