
import { defineComponent, inject, computed } from "vue";
import { key as itemStoreKey } from "@/stores/items";

export default defineComponent({
    name: "ExplainBox",
    setup() {
        const itemStore = inject(itemStoreKey);

        //説明対象のアイテムがあるか
        const isVisible = computed(() => {
            const item = itemStore?.hoverItem;
            return item !== undefined;
        });

        const name = computed(() => {
            const item = itemStore?.hoverItem;
            return item === undefined ? "" : item.name;
        });

        const message = computed(() => {
            const item = itemStore?.hoverItem;
            return item === undefined ? "" : item.explain.message;
        });

        const specialMessage = computed(() => {
            const item = itemStore?.hoverItem;
            return item === undefined ? "" : item.explain.specialMessage;
        });

        const img = computed(() => {
            const item = itemStore?.hoverItem;
            return item === undefined
                ? ""
                : item.explain.img !== undefined
                ? item.explain.img
                : item.img;
        });

        return {
            isVisible,
            name,
            message,
            specialMessage,
            img,
        };
    },
});
