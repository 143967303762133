import axios from "axios";
import { InjectionKey, ref } from "vue";
import { PopupParam } from "./operation";

export type ItemType = {
    name: string;
    img: string;
    x: number;
    y: number;
    explain: {
        message: string;
        img?: string;
        specialMessage?: string;
        specialFlag?: boolean;
    };
    shadow?: {
        img: string;
        x: number;
        y: number;
    },
    click: {
        url?: string;
        popup?: PopupParam;
    },
    date?: string[];
};

// eslint-disable-next-line
export default function itemStore() {
    const items = ref([] as ItemType[]);
    const hoverItemIndex = ref(-1);

    return {
        loadItems() {
            axios.get('items.json')
                .then((responce) => {
                    items.value = responce.data;
                });
        },

        get items() {
            //表示期間内のアイテムのみを返す
            const retItems = [] as ItemType[];
            items.value.forEach((item) => {
                if (item.date !== undefined) {
                    //表示期間内かチェック
                    if (isCurrent(item.date)) {
                        retItems.push(item);
                    }
                } else {
                    retItems.push(item);
                }
            })
            return retItems;


            return items.value;
        },

        set hoverItemIndex(index: number) {
            hoverItemIndex.value = index;
        },
        get hoverItemIndex() {
            return hoverItemIndex.value;
        },
        get hoverItem(): ItemType | undefined {
            if (hoverItemIndex.value === -1) {
                return undefined;
            }
            return items.value[hoverItemIndex.value];
        }
    }
}
export const key: InjectionKey<ReturnType<typeof itemStore>> = Symbol('itemStore');

/**
 * 現在表示期間中かチェック
 * @param {Array} dates 
 */
function isCurrent(dates: string[]) {
    const now = new Date();
    const month = now.getMonth() + 1; // 月
    const day = now.getDate(); // 日

    return dates.some(function (date) {
        if (date.indexOf("-") !== -1) {
            //期間指定の場合
            const fromto = date.split("-");
            const from = getMonthDay(fromto[0]);
            const to = getMonthDay(fromto[1]);

            if (from.month <= to.month && from.day <= to.day) {
                //from < to の場合
                return isBetween(month, day, from, to);
            } else {
                //from > to の場合（ちゃんとテストしてないので間違ってるかも）
                if (month === from.month && day === from.day) return true;
                if (month === to.month && day === to.day) return true;
                const res = isBetween(month, day, to, from);
                return !res;
            }
        } else {
            const from = getMonthDay(date);
            if (month === from.month && day === from.day) return true;
            return false;
        }
    });
}

type DayObj = {
    month: number;
    day: number;
};
function isBetween(month: number, day: number, from: DayObj, to: DayObj) {
    if (month < from.month) return false;
    if (month > to.month) return false;
    if (month === from.month && day < from.day) return false;
    if (month === to.month && day > to.day) return false;
    return true;
}

/**
 * {month, day}を返す
 * @param {string} datestr MM/DD形式の文字列 
 */
function getMonthDay(datestr: string): DayObj {
    const temp = datestr.split("/");
    return {
        month: parseInt(temp[0]),
        day: parseInt(temp[1])
    };
}