<template>
    <div id="header">
        <!-- タイトル -->
        <img src="./assets/title.gif" />
    </div>

    <div id="contents">
        <MessageBox id="message_box" />
        <RoomMap id="map_box" />
        <ExplainBox id="explain_box" />
        <PopupWindow v-if="popupShow" />
    </div>

    <div id="footer">
        <!-- メールアドレス -->
        <i>
            Copyright(c)2006
            <a href="mailto:satoko@suzuki.email.ne.jp">
                <b>Satoko.S</b>
            </a>
            All Rights Reserved.
        </i>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, provide } from "vue";
import MessageBox from "./components/MessageBox.vue";
import PopupWindow from "./components/PopupWindow.vue";
import RoomMap from "./components/RoomMap.vue";
import ExplainBox from "./components/ExplainBox.vue";
import itemStore, { key as itemStoreKey } from "@/stores/items";
import historyStore, { key as historyStoreKey } from "@/stores/history";
import operationStore, { key as operationStoreKey } from "@/stores/operation";

export default defineComponent({
    name: "App",
    components: {
        MessageBox,
        PopupWindow,
        RoomMap,
        ExplainBox,
    },
    setup() {
        const myItemStore = itemStore();
        myItemStore.loadItems();
        provide(itemStoreKey, myItemStore);

        const myHistoryStore = historyStore();
        myHistoryStore.loadHistory();
        provide(historyStoreKey, myHistoryStore);

        const myOperationStore = operationStore();
        provide(operationStoreKey, myOperationStore);

        const popupShow = computed(() => myOperationStore.popup.visible);
        return {
            popupShow,
        };
    },
});
</script>

<style scoped>
@media screen and (orientation: landscape) {
    /* 横向き */
    #header {
        text-align: center;
    }

    #footer {
        width: 100%;
        text-align: center;
    }

    #contents {
        margin: 0 auto;

        width: 940px;
        height: 450px;
    }

    #contents.after {
        clear: both;
    }

    /* コンテンツ内 */
    #message_box {
        margin: 0 auto 10px;
        width: 300px;
        height: 190px;
        float: right;
    }

    #map_box {
        width: 600px;
        float: left;
    }

    #explain_box {
        width: 300px;
        height: 200px;

        border: 8px dashed;
        float: right;
    }
}

@media screen and (orientation: portrait) {
    /* 縦向き */
    #header {
        width: 90%;
        margin: 0 auto;
        text-align: center;
    }

    #footer {
        margin: 0 auto;
        bottom: 0;
        width: 90%;
        text-align: center;
    }

    #contents {
        margin: 0 auto;

        width: 90%;
    }

    /* コンテンツ内 */
    #message_box {
        margin: 0 auto;
        padding: 5px;
        width: 600px;
        height: 100px;
        overflow-y: scroll;
    }

    #map_box {
        margin: 0 auto;
        margin: 10px auto 10px;
        width: 600px;
    }

    #explain_box {
        margin: 0 auto;
        width: 600px;
        height: 200px;

        border: 8px dashed;
    }
}
</style>
