
import { defineComponent, inject, computed } from "vue";
import { key as historyStoreKey } from "@/stores/history";

export default defineComponent({
    name: "History",
    setup() {
        const historyStore = inject(historyStoreKey);
        const histories = computed(() => {
            let his = historyStore?.histories.concat();
            his = his?.reverse();
            return his;
        });
        return {
            histories,
        };
    },
});
