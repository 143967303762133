
import { computed, defineComponent, provide } from "vue";
import MessageBox from "./components/MessageBox.vue";
import PopupWindow from "./components/PopupWindow.vue";
import RoomMap from "./components/RoomMap.vue";
import ExplainBox from "./components/ExplainBox.vue";
import itemStore, { key as itemStoreKey } from "@/stores/items";
import historyStore, { key as historyStoreKey } from "@/stores/history";
import operationStore, { key as operationStoreKey } from "@/stores/operation";

export default defineComponent({
    name: "App",
    components: {
        MessageBox,
        PopupWindow,
        RoomMap,
        ExplainBox,
    },
    setup() {
        const myItemStore = itemStore();
        myItemStore.loadItems();
        provide(itemStoreKey, myItemStore);

        const myHistoryStore = historyStore();
        myHistoryStore.loadHistory();
        provide(historyStoreKey, myHistoryStore);

        const myOperationStore = operationStore();
        provide(operationStoreKey, myOperationStore);

        const popupShow = computed(() => myOperationStore.popup.visible);
        return {
            popupShow,
        };
    },
});
