<!-- メッセージボックス -->
<template>
    <div class="message">
        <p align="left">
            <span style="font-size: small">
                最終システム更新日：{{ lastupdate }}（<a
                    href="javascript:void(0)"
                    @click="openHistory"
                    >更新履歴</a
                >）<br />
                ※各コンテンツは随時更新してます。
            </span>
        </p>
        <p>{{ lastupdateDetail }}</p>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject, computed } from "vue";
import { key as historyStoreKey } from "@/stores/history";
import { key as operationStoreKey } from "@/stores/operation";
import History from "./History.vue";

export default defineComponent({
    name: "MessageBox",

    // eslint-disable-next-line
    setup() {
        const historyStore = inject(historyStoreKey);
        const operationStore = inject(operationStoreKey);
        const lastupdate = computed(() => historyStore?.lastHistory.date);
        const lastupdateDetail = computed(
            () => historyStore?.lastHistory.contents
        );

        const openHistory = () => {
            console.log("openHistory");
            //history.htmlを表示する
            operationStore?.showPopup({
                title: "更新履歴",
                component: "History",
                width: 640,
                height: 400,
            });
        };

        return {
            lastupdate,
            lastupdateDetail,
            openHistory,
        };
    },
});
</script>

<style scoped>
.message {
    overflow-y: auto;
    padding: 5px;
}
</style>
