<!-- ポップアップウィンドウ -->
<template>
    <div id="popupWindow" class="contents-back" :style="windowStyle">
        <div ref="title" class="popupTitle">
            {{ title }}
            <div id="popupClose" @click="close"></div>
        </div>
        <iframe
            ref="contents"
            id="popupContents"
            :src="url"
            v-if="url !== null"
        />
        <component
            id="popupContents"
            v-bind:is="component"
            v-else-if="component !== null"
        />
    </div>
</template>

<script>
import { defineComponent, inject, computed, reactive, onMounted } from "vue";
import { key as operationStoreKey } from "@/stores/operation";
import History from "./History.vue";

export default defineComponent({
    name: "PopupWindow",
    components: {
        History,
    },
    setup() {
        const operationStore = inject(operationStoreKey);

        const state = reactive({
            left: 0,
            top: 0,
            height: 0,
            width: 0,
            dragStartX: 0,
            dragStartY: 0,
        });

        onMounted(() => {
            //位置の初期設定
            state.height = operationStore.popup.height;
            state.width = operationStore.popup.width;
            state.top = (window.innerHeight - state.height) / 2;
            state.left = (window.innerWidth - state.width) / 2;
        });

        const title = computed(() => operationStore.popup.title);
        const url = computed(() => operationStore.popup.url);
        const component = computed(() => {
            console.log("component", operationStore.popup.component);
            return operationStore.popup.component;
        });
        const windowStyle = computed(() => {
            return {
                height: state.height + "px",
                width: state.width + "px",
                top: state.top + "px",
                left: state.left + "px",
            };
        });
        return {
            title,
            url,
            component,
            windowStyle,
            close: () => operationStore.hidePopup(),
        };
    },
});
</script>

<style scoped>
#popupWindow {
    position: absolute;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.26));
}
.popupTitle {
    color: #ffffff;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    height: 1.5em;

    border-radius: 5px 5px 0px 0px;
}

#popupContents {
    overflow-y: auto;
    width: 100%;
    height: calc(100% - 1.5em); /* titleの高さを引く */
    border: none;
    border-radius: 0px 0px 5px 5px;
}

#popupClose {
    position: absolute;
    background-image: url("../assets/close.gif");
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    top: 0.3em;
    right: 5px;
    cursor: pointer;
}
</style>
