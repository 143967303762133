import { InjectionKey, ref } from "vue";
import axios from 'axios';

// eslint-disable-next-line
export default function historyStore() {
    const histories = ref([{ date: '', contents: '' }]);

    return {
        loadHistory() {
            axios.get('history.json')
                .then((response) => {
                    histories.value = response.data;
                });
        },

        get lastHistory() {
            return histories.value[histories.value.length - 1];
        },

        get histories() {
            return histories.value;
        },
    }
}
export const key: InjectionKey<ReturnType<typeof historyStore>> = Symbol('historyStore');
