<!-- 部屋マップ -->
<template>
    <div id="map_box" ref="root"></div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, inject, computed, watch } from "vue";
import * as PIXI from "pixi.js";
import { key as itemStoreKey } from "@/stores/items";
import { key as operationStoreKey } from "@/stores/operation";

export default defineComponent({
    name: "RoomMap",
    setup() {
        let map = undefined as PIXI.Application | undefined;

        const root = ref(null);
        onMounted(() => {
            // キャンバス生成
            map = new PIXI.Application({
                width: 600,
                height: 430,
                backgroundColor: 0xffffff,
            });
            // eslint-disable-next-line
            const rootDiv = (root.value as any) as HTMLElement;
            rootDiv.appendChild(map.view);

            placeItems();
        });

        const itemStore = inject(itemStoreKey);
        const items = computed(() => itemStore?.items);

        const operationStore = inject(operationStoreKey);
        const hoverItemIndex = ref(-1);

        const placeItems = () => {
            map?.stage.removeChildren();

            //背景画像
            const texture = PIXI.Texture.from("img/room.png");
            const room = new PIXI.Sprite(texture);

            map?.stage.addChild(room);

            //各アイテムの配置
            items.value?.forEach((item, index) => {
                // 影
                if (item.shadow !== undefined) {
                    let shadowTexture = PIXI.Texture.from(item.shadow.img);
                    let shadowObj = new PIXI.Sprite(shadowTexture);
                    shadowObj.position.x = item.shadow.x;
                    shadowObj.position.y = item.shadow.y;
                    shadowObj.alpha = 1;
                    map?.stage.addChild(shadowObj);
                }

                // アイテム画像
                let itemTexture = PIXI.Texture.from(item.img);
                let obj = new PIXI.Sprite(itemTexture);
                obj.position.x = item.x;
                obj.position.y = item.y;
                map?.stage.addChild(obj);

                //マウスhover時の挙動登録
                obj.interactive = true;
                const mouseoverFunc = function () {
                    if (itemStore !== undefined)
                        itemStore.hoverItemIndex = index;
                    //連続して物に触れると、誤った解除がされてしまうので、hoverItemIndexに最後に触ったアイテムを記録しておく
                    hoverItemIndex.value = index;
                };
                obj.on("mouseover", mouseoverFunc);
                obj.on("touchstart", mouseoverFunc);

                const mouseoutFunc = function () {
                    if (hoverItemIndex.value === index) {
                        if (itemStore !== undefined)
                            itemStore.hoverItemIndex = -1;
                        hoverItemIndex.value = -1;
                    }
                };
                obj.on("mouseout", mouseoutFunc);
                obj.on("touchend", mouseoutFunc);

                //マウスクリック時の挙動登録
                if (item.click !== undefined) {
                    obj.buttonMode = true;
                    const clickFunc = () => {
                        operationStore?.itemClicked(item);
                    };
                    obj.on("click", clickFunc);
                    obj.on("touchend", clickFunc);
                }
            });
        };
        watch(items, placeItems);

        return {
            root,
            map,
            items,
            hoverItemIndex,
        };
    },
});
</script>

<style scoped>
#map_box {
    width: 600px;
    height: 430px;
    background: #ffffff;
}
</style>
